<template>
    <div class="flip-counter-wrapper font-weight-bolder">
        <div class="nums nums-ten">
            <div class="num" data-num="0" data-num-next="1"></div>
            <div class="num" data-num="1" data-num-next="2"></div>
            <div class="num" data-num="2" data-num-next="3"></div>
            <div class="num" data-num="3" data-num-next="4"></div>
            <div class="num" data-num="4" data-num-next="5"></div>
            <div class="num" data-num="5" data-num-next="6"></div>
            <div class="num" data-num="6" data-num-next="7"></div>
            <div class="num" data-num="7" data-num-next="8"></div>
            <div class="num" data-num="8" data-num-next="9"></div>
            <div class="num" data-num="9" data-num-next="0"></div>
        </div>

        <div class="nums nums-one">
            <div class="num" data-num="0" data-num-next="1"></div>
            <div class="num" data-num="1" data-num-next="2"></div>
            <div class="num" data-num="2" data-num-next="3"></div>
            <div class="num" data-num="3" data-num-next="4"></div>
            <div class="num" data-num="4" data-num-next="5"></div>
            <div class="num" data-num="5" data-num-next="6"></div>
            <div class="num" data-num="6" data-num-next="7"></div>
            <div class="num" data-num="7" data-num-next="8"></div>
            <div class="num" data-num="8" data-num-next="9"></div>
            <div class="num" data-num="9" data-num-next="0"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FlipCounter",
        props: {
            value: {
                type: [String, Number]
            }
        },
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
        watch: {
            "value" (newVal) {

            }
        }
    }
</script>

<style lang="scss" scoped>
    .flip-counter-wrapper {
        height: 200px;
        position: relative;
        text-align: center;

        .nums {
            box-shadow: 0 3px 10px #111;
            border-top: 1px solid #393939;
            border-radius: 6px;

            display: inline-block;

            height: 200px;
            margin: 0 10px;

            perspective: 1000px;
            position: relative;
            width: 140px;

            &:before {
                border-bottom: 2px solid black;

                content: '';

                height: 1px;
                width: 100%;

                position: absolute;
                top: 50%;
                left: 0;

                transform: translate3d(0,-1px,0);

                z-index: 1000;
            }

            &:after {
                position: absolute;
                left: 0;
                bottom: 0;

                height: calc(50% - 1px);
                width: 100%;

                display: block;

                backface-visibility: hidden;
                background: #2a2a2a;

                border-bottom: 1px solid #444444;
                border-top: 1px solid black;
                border-radius: 0 0 5px 5px;
                box-shadow: inset 0 15px 50px #202020;

                color: #eeeeee;
                content: "0";

                font-size: 145px;
                text-align: center;
                text-shadow: 0 1px 2px #333;

                line-height: 0;

                overflow: hidden;

                z-index: 0;
            }
        }

        .num {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            border-radius: 5px;
            font-size: 145px;

            transform: rotateX(0);
            transition: 0.6s;
            transform-style: preserve-3d;

            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in;

            &:before,
            &:after {
                position: absolute;
                left: 0;
                height: 50%;
                width: 100%;

                display: block;

                color: #eeeeee;

                text-align: center;
                text-shadow: 0 1px 2px #333;

                backface-visibility: hidden;
                overflow: hidden;
            }

            &:before {
                top: 0;

                background: #181818;
                border-radius: 5px 5px 0 0;
                box-shadow: inset 0 15px 50px #111111;

                content: attr(data-num);
                line-height: 1.38;

                z-index: 1;
            }

            &:after {
                top: 0;
                height: calc(50% - 1px);

                background: #2a2a2a;
                border-bottom: 1px solid #444444;
                border-radius: 0 0 5px 5px;
                box-shadow: inset 0 15px 50px #202020;

                content: attr(data-num-next);
                line-height: 0;

                transform: rotateX(180deg);
            }
        }

        @for $i from 1 through 10 {
            .nums-one .num:nth-of-type(#{$i}) {
                animation-name: num-one;
                animation-delay: $i - 1s;
                animation-duration: 10s;

                z-index: (10 - $i) + 1;
            }

            .nums-ten .num:nth-of-type(#{$i}) {
                animation-name: num-ten;
                animation-delay: ($i * 10s) - 1s;
                animation-duration: 100s;

                z-index: (10 - $i) + 1;
            }
        }

        @keyframes num-one {
            0% {
                transform: rotateX(0);
                z-index: 50;
            }

            10% {
                transform: rotateX(-180deg);
                z-index: 50;
            }

            90% {
                transform: rotateX(-180deg);
                z-index: 1;
            }

            90.0001% {
                transform: rotateX(0);
            }

            100% {
                transform: rotateX(0);
            }
        }

        @keyframes num-ten {
            0% {
                transform: rotateX(0);
                z-index: 50;
            }

            1% {
                transform: rotateX(-180deg);
                z-index: 50;
            }

            90% {
                transform: rotateX(-180deg);
                z-index: 1;
            }

            90.0001% {
                transform: rotateX(0);
            }

            100% {
                transform: rotateX(0);
            }
        }

    }
</style>
